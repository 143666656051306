<template>
    <div class="TransferOfMoney">



        <a-tabs v-model:activeKey="activeKey" type="card">
            <a-tab-pane key="1" tab="单笔转账"></a-tab-pane>
            <a-tab-pane key="4" tab="批量制单" :disabled="user.roule !== 0 && user.roule !== 1"></a-tab-pane>
            <a-tab-pane key="2" tab="打款列表"></a-tab-pane>
            <!-- <a-tab-pane key="3" tab="多笔转账" :disabled="user.roule !== 0"></a-tab-pane> -->
        </a-tabs>

        <!-- 批量制单 -->
        <div class="clearfix" v-if="activeKey === '4'">
            <a-upload-dragger v-model:file-list="fileList" list-type="picture" :before-upload="beforeUpload"
                @change="uploadDraggerChange" @remove="handleRemove" :max-count="1">
                <p class="ant-upload-drag-icon">
                    <inbox-outlined></inbox-outlined>
                </p>
                <p style="margin: 10px;">点击或拖拽上传</p>

                <a-button style="margin: 20px 0;" type="primary" @click.stop="DownloadTemplateZD">下载批量制单模板</a-button>
            </a-upload-dragger>
            <a-button type="primary" :disabled="fileList.length === 0" :loading="uploading" style="margin-top: 16px"
                @click="handleUpload(1)">
                {{ uploading ? '正在加载' : '确认制单' }}
            </a-button>





            <!-- <div style="height:350px;margin-top: 20px;" v-if="batchData.length !== 0">
                <a-table :columns="batchColumns" :data-source="batchData" :scroll="{ x: 1500, y: '300px' }">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-button type="primary" @click="OpenSuborderOpen(record)">制单详情</a-button>
                        </template>
</template>
</a-table>
</div>



<a-modal v-model:open="suborderOpen" title="制单详情" ok-text="确认" cancel-text="取消" width="80%"
    @ok="shenheOpen = !shenheOpen">
    <a-table :row-selection="rowSelections" :columns="suborderColumns" :data-source="suborderData"></a-table>
</a-modal> -->



            <div class="piliangyulan_box" v-if="jsonData.length !== 0">
                <div class="piliangyulan_box_title">
                    <div v-for="(item, index) in jsonDataTitle[0]" :key="index" class="piliangyulan_box_title_item">
                        {{ item }}
                    </div>
                </div>
                <div class="piliangyulan_box_table">
                    <div v-for="(item, index) in jsonData" class="piliangyulan_box_table_row"
                        :style="index === 0 ? 'border-top:none;' : ''" :key="index">
                        <div v-for="(items, indexs) in item" class="piliangyulan_box_table_row_item" :key="indexs">
                            <div>{{ items }}</div>
                        </div>
                    </div>
                </div>
            </div>


















        </div>


        <div class="clearfix" v-if="activeKey === '3'">
            <a-upload-dragger v-model:file-list="fileList" list-type="picture" :before-upload="beforeUpload"
                @remove="handleRemove" :max-count="1">
                <p class="ant-upload-drag-icon">
                    <inbox-outlined></inbox-outlined>
                </p>
                <p style="margin: 10px;">点击或拖拽上传</p>

                <a-button style="margin: 20px 0;" type="primary" @click.stop="DownloadTemplate">下载批量支付模板</a-button>
            </a-upload-dragger>
            <a-button type="primary" :disabled="fileList.length === 0" :loading="uploading" style="margin-top: 16px"
                @click="handleUpload(0)">
                {{ uploading ? '正在加载' : '确认转账' }}
            </a-button>
        </div>


        <!-- 打款列表 -->
        <div v-if="activeKey === '2'">
            <!-- <div class="rolemanagement_box">
                <a-table class="components-table-demo-nested" :columns="columns" :data-source="list" border
                    :scroll="{ y: 580 }" :pagination="false">

                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'amount'">
                            <span style="color:red;">{{ record.amount }}</span>
                        </template>
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-space warp>
                                <a-button type="primary" v-if="user.roule === 1 || user.roule === 0"
                                    :disabled="record.status === '2' ? true : false"
                                    @click="api_cw_cancle_dkFun(record)">
                                    取消制单
                                </a-button>
                                <a-button type="primary" v-if="(user.roule === 3 || user.roule === 0)"
                                    :disabled="record.status === '1' ? false : true" @click="MakePaymentFun(record)">
                                    去审核
                                </a-button>
                            </a-space>
                        </template>
                    </template>
                </a-table>
            </div>
<a-pagination style=" margin-top: 20px;" v-model:current="current" simple :total="total" @change="paginationChange"
    :pageSize="10" :hideOnSinglePage="false" /> -->

            <div class="rolemanagement_box" v-if="batchData.length !== 0">
                <a-table :columns="batchColumns" :data-source="batchData" :scroll="{ x: 1500, y: 580 }">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'Controls'">
                            <a-button type="primary" @click="OpenSuborderOpen(record)"
                                style="margin-bottom: 10px;">制单详情</a-button>
                            <!-- <a-button :disabled="(user.roule === 3 || user.roule === 0) ? false : true" type="primary" @click="OpenSuborderOpen(record)" style="margin-bottom: 10px;">制单详情</a-button> -->
                            <a-button type="primary" danger @click="api_cw_new_cancle_dkFun(record)"
                                v-if="record.yidakuan === 0"
                                :disabled="(user.roule === 1 || user.roule === 0) ? false : true">撤回制单</a-button>

                        </template>
                    </template>
                </a-table>
            </div>



            <a-modal v-model:open="suborderOpen" title="制单详情" :footer="null" width="80%" @ok="shenheOpen = !shenheOpen">
                <a-descriptions bordered size="middle" style="padding: 50px;" title='详情'>
                    <template #extra v-if="piliangdata.yidakuan === 0">
                        <a-button type="primary" @click="showConfirm">立即打款</a-button>
                    </template>
                    <a-descriptions-item label="批次号">{{ piliangdata.ordersn }} </a-descriptions-item>
                    <a-descriptions-item label="审核状态">审核通过</a-descriptions-item>
                    <a-descriptions-item label="实际手续费">{{ piliangdata.rate }}</a-descriptions-item>
                    <a-descriptions-item label="提交时间">{{ piliangdata.time }}</a-descriptions-item>
                    <a-descriptions-item label="批次金额">{{ piliangdata.all_money }}</a-descriptions-item>
                    <!-- <a-descriptions-item label="成功金额"></a-descriptions-item> -->
                    <a-descriptions-item label="笔数">{{ piliangdata.su_num }}</a-descriptions-item>
                    <!-- <a-descriptions-item label="成功笔数"></a-descriptions-item> -->
                    <a-descriptions-item label="操作员">{{ piliangdata.payAccName }} </a-descriptions-item>
                </a-descriptions>

                <div style="width: 100%;display: flex;justify-content: space-around;">
                    <a-button type="primary" size="middle" style="width: 300px;"
                        @click="OpenSuborderOpen">查看子订单</a-button>
                    <a-button size="middle" style="width: 300px;"
                        @click="api_cw_export_dataFun(piliangdata.id)">打印</a-button>
                    <a-button size="middle" style="width: 300px;" @click="suborderOpen = !suborderOpen">关闭</a-button>
                </div>








                <a-modal v-model:open="suborderOpens" title="查看子订单" width="80%" :footer="null">

                    <a-table :columns="suborderColumns" :data-source="suborderData" :pagination="{ pageSize: 1000 }"
                        :scroll="{ y: 400 }">


                    </a-table>

                </a-modal>








            </a-modal>
        </div>


















        <!-- 单笔制单 -->
        <a-form v-if="activeKey === '1'" :model="formState" :rules="rules" name="basic" :label-col="{ span: 2 }"
            :wrapper-col="{ span: 16 }" autocomplete="off" @finish="api_cw_document_preparationFun"
            @finishFailed="onFinishFailed" :layout="layout">



            <a-divider orientation="left">付款方信息</a-divider>
            <a-form-item label="本方账户名称" name="payAccName" :rules="[{ required: true, message: '本方账户名称不得为空' }]">
                <a-input v-model:value="formState.payAccName" />
            </a-form-item>

            <a-form-item label="本方账户" name="payAccNo" :rules="[{ required: true, message: '本方账户不得为空' }]">
                <a-input v-model:value="formState.payAccNo" />
            </a-form-item>

            <a-form-item label="转账金额" name="amount"
                :rules="[{ required: true, message: '转账金额不得为空', trigger: 'change' }]">
                <a-input size="large" placeholder="请输入转账金额" v-model:value="formState.amount" />
            </a-form-item>


            <a-divider orientation="left">收款方信息</a-divider>

            <a-form-item label="收款账户名称" name="recAccName" :rules="[{ required: true, message: '收款账户名称不得为空' }]">
                <a-input v-model:value="formState.recAccName" />
            </a-form-item>

            <a-form-item label="收款账户" name="recAccNo" :rules="[{ required: true, message: '收款账户不得为空' }]">
                <a-input v-model:value="formState.recAccNo" />
            </a-form-item>

            <a-form-item label="行联号" v-if="formState.isSameBank === '2'" name="recBankNo"
                :rules="[{ required: true, message: '行联号不得为空' }]">
                <a-input v-model:value="formState.recBankNo" />
            </a-form-item>
            <a-form-item label="银行名称" v-if="formState.isSameBank === '2'" name="recBankName"
                :rules="[{ required: true, message: '银行名称不得为空' }]">
                <a-input v-model:value="formState.recBankName" />
            </a-form-item>

            <a-divider orientation="left">其他信息</a-divider>

            <a-form-item label="是否跨行" name="isSameBank" :rules="[{ required: true }]">
                <a-radio-group v-model:value="formState.isSameBank">
                    <a-radio value="1">否</a-radio>
                    <a-radio value="2">是</a-radio>
                </a-radio-group>
            </a-form-item>

            <a-form-item label="附言" name="memo">
                <a-input v-model:value="formState.memo" />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 1, span: 1 }">
                <a-button type="primary" html-type="submit" style="width: 100%;"
                    :disabled="(user.roule === 0 || user.roule === 1) ? false : true">制单</a-button>
            </a-form-item>
        </a-form>







        <a-modal v-model:open="dakuanOpen" title="确认转账吗？确认后金额立即转出" okText="确认" cancelText="关闭" @ok="zhuanzhangFun">




            <!-- <a-space>
                <a-select ref="select" v-model:value="auditType" style="width: 120px">
                    <a-select-option value="1">同意</a-select-option>
                    <a-select-option value="0">拒绝</a-select-option>
                </a-select>
            </a-space> -->


        </a-modal>

        <a-modal v-model:open="shenheOpen" title="打款操作" okText="确认" cancelText="取消" @ok="shenheOpenFun">

            <a-space>
                <a-select ref="select" v-model:value="auditTypes" style="width: 120px">
                    <a-select-option value="0">拒绝</a-select-option>
                    <a-select-option value="1">同意</a-select-option>
                </a-select>
            </a-space>

        </a-modal>



    </div>
</template>

<script lang="ts">
import { reactive, ref, customRef, onMounted, watch, h, computed } from 'vue';
import {
    api_cw_single_pay,
    api_cw_pl_pay,
    api_cw_document_preparation,
    api_cw_get_zd_list,
    api_cw_to_examine,
    api_index_pl_zd,
    api_cw_jujue_dk,
    api_cw_cancle_dk,
    api_cw_get_zd,
    api_cw_get_zd_detail,
    api_cw_new_cancle_dk,
    api_cw_piliang_single_pay,
    api_cw_export_data
} from '@/api/home'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons-vue';
import type { UploadChangeParam, UploadProps, TableColumnsType, TableProps } from 'ant-design-vue';
import { message, Modal } from 'ant-design-vue';
import router from "@/router";
import { useStore } from 'vuex';
// 局部引入
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';


// 在main.js中引入XLSX


export default {
    name: 'TransferOfMoney',
    components: {
        UploadOutlined,
        InboxOutlined
    },
    setup() {







        const api_cw_export_dataFun = async (id: string) => {
            const up = {
                openid: user.openid,
                id
            }
            const data: any = await api_cw_export_data(up);
            console.log(data);

            let arr: any = []

            data.list.forEach((item: any) => {
                console.log(item);
                let obj: any = {}
                for (let key in item) {
                    console.log(key);

                    switch (true) {
                        case key === 'amount':
                            obj['打款金额'] = item['amount']
                            break;
                        case key === 'payAccName':
                            obj['操作员'] = item['payAccName']
                            break;
                        case key === 'recAccName':
                            obj['收款人'] = item['recAccName']
                            break;
                        case key === 'recAccNo':
                            obj['收款账号'] = item['recAccNo']
                            break;
                        case key === 'recBankName':
                            obj['收款银行'] = item['recBankName']
                            break;
                        case key === 'status_name':
                            obj['状态'] = item['status_name']
                            break;
                        case key === 'time':
                            obj['操作时间'] = item['time']
                            break;
                    }
                }
                arr.push(obj)
            })
            console.log(arr);


            // return
            const ws = XLSX.utils.json_to_sheet(arr);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
            function s2ab(s: any) {

                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                console.log(view);
                for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            FileSaver.saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), `${data.ordersn}制单详情.xlsx`);









        }




        const showConfirm = () => {
            Modal.confirm({
                title: '确认转账吗？',
                content: '确认后金额立即转出',
                okText: '确认',
                cancelText: '取消',
                async onOk() {
                    try {
                        return await new Promise((resolve, reject) => {
                            zhuanzhangFun(resolve, reject);
                        });
                    } catch {
                        return console.log('Oops errors!');
                    }
                },
                onCancel() { },
            });
        }



        const uploadDraggerChange = (e: any) => {

            handleFile(e.file)
        }

        let jsonData: any = ref([])
        let jsonDataTitle: any = ref([])

        const handleFile = (file: any) => {
            console.log(file);
            jsonData.value = [];
            // const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                console.log(e);

                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // 假设我们读取第一个工作表
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                // console.log(worksheet);

                // 将工作表内容转换为 JSON 格式
                let arr = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                console.log(jsonData.value);
                jsonDataTitle.value = arr.splice(0, 1);
                console.log(jsonDataTitle.value);

                arr.forEach((item: any) => {
                    // item[0] = item[0] ? item[0] : '空';
                    // item[1] = item[1] ? item[1] : '空';
                    // item[2] = item[2] ? item[2] : '空';
                    // item[3] = item[3] ? item[3] : '空';
                    // item[4] = item[4] ? item[4] : '空';
                    // item[5] = item[5] ? item[5] : '空';
                    // jsonData.value.push(item)

                    if (item[0] !== undefined) {
                        jsonData.value.push(item)
                        item[5] = item[5] ? item[5] : '空';
                    } else {
                        return
                    }
                })

            };

            reader.readAsArrayBuffer(file);
        };

        // const handleFile = (file: any): Boolean => {
        //     const reader = new FileReader();
        //     reader.onload = (e: any) => {
        //         const data = new Uint8Array(e.target.result);
        //         const workbook = XLSX.read(data, { type: 'array' });
        //         const firstSheetName = workbook.SheetNames[0];
        //         const worksheet = workbook.Sheets[firstSheetName];
        //         const jsonData = XLSX.utils.sheet_to_json(worksheet);
        //         console.log(jsonData);
        //         console.log(worksheet);


        //         // 处理jsonData，例如：存储到Vuex或者发送到服务器等
        //     };
        //     reader.readAsArrayBuffer(file);
        //     return false; // 阻止默认上传行为
        // }

        function handleRemoves(file: any) {
            const index = fileList.value.indexOf(file);
            if (index > -1) {
                fileList.value.splice(index, 1);
            }
        }

        const handleRemove: UploadProps['onRemove'] = file => {
            jsonData.value = []
            const index = fileList.value.indexOf(file);
            const newFileList = fileList.value.slice();
            newFileList.splice(index, 1);
            fileList.value = newFileList;
        };






        interface FormState {
            payAccName: string;
            payAccNo: string;
            recAccName: string;
            recAccNo: string;
            amount: number | null;
            isSameBank: string,
            recBankNo: string;
            recBankName: string;
            openid: string;
            memo: string;
        }

        let user: any = sessionStorage.getItem('user');
        user = JSON.parse(user);

        const formState = reactive<FormState>({
            payAccName: user.payAccName,
            payAccNo: user.payAccNo,
            recAccName: '',
            recAccNo: '',
            amount: null,
            isSameBank: "1",
            recBankNo: '',
            recBankName: '',
            openid: user.openid,
            memo: ''
        });

        // -----------------------------
        type Key = string | number;
        const state = reactive<{
            selectedRowKeys: Key[];
            loading: boolean;
        }>({
            selectedRowKeys: [], // Check here to configure the default column
            loading: false,
        });



        const onSelectChange = (selectedRowKeys: Key[], row: any) => {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            console.log(row, 'row');

            state.selectedRowKeys = selectedRowKeys;
            BatchAuditData.value = row;
        };


        // -----------------------------

        const store = useStore();



        onMounted(() => {
            let user = JSON.parse(store.state.user);
            console.log(user);
        })


        const api_cw_document_preparationFun = async (values: FormState) => {
            values.openid = user.openid
            let data: any = await api_cw_document_preparation(values);
            console.log(data);

            if (data.error === 0) {
                setTimeout(() => {
                    router.push({ path: '/home' });
                }, 1000)
            }
        };





        const onFinishFailed = () => {
            message.error('校验未通过请检查参数');
        };




        //批量表头
        const batchColumns: TableColumnsType = [
            { title: '批次号', width: 200, dataIndex: 'ordersn' },
            { title: '批次金额', width: 200, dataIndex: 'all_money' },
            { title: '提交时间', width: 200, dataIndex: 'time' },
            { title: '批次笔数', width: 100, dataIndex: 'num' },
            { title: '手续费', width: 100, dataIndex: 'rate' },
            { title: '操作员', width: 400, dataIndex: 'auth' },
            { title: '成功笔数', width: 200, dataIndex: 'su_num' },
            { title: '成功金额', width: 200, dataIndex: 'su_money' },
            {
                title: '操作', width: 200, dataIndex: 'Controls', fixed: 'right'
            },
        ];

        //批量子表头
        const suborderColumns: TableColumnsType = [
            // { title: '选择', width: '50px', dataIndex: 'isSelect' },
            { title: '收款人账号', width: 200, dataIndex: 'recAccNo' },
            { title: '收款户名', width: 100, dataIndex: 'recAccName' },
            { title: '是否跨行', width: 100, dataIndex: 'isSameBank' },
            { title: '打款金额', width: 100, dataIndex: 'amount' },
            { title: '附言', width: 100, dataIndex: 'memo' },
            { title: '行号', width: 100, dataIndex: 'recBankNo' },
            { title: '银行名称', width: 100, dataIndex: 'recBankName' },
            { title: '状态', width: 100, dataIndex: 'status_name' },
            { title: '操作时间', width: 200, dataIndex: 'time' }
        ];

        //制单表头
        const columns: TableColumnsType = [
            // { title: '选择', width: '50px', dataIndex: 'isSelect' },
            { title: '收款人账号', width: 200, dataIndex: 'recAccNo' },
            { title: '收款户名', width: 100, dataIndex: 'recAccName' },
            { title: '是否跨行', width: 100, dataIndex: 'isSameBank' },
            { title: '打款金额', width: 100, dataIndex: 'amount' },
            { title: '附言', width: 100, dataIndex: 'memo' },
            { title: '行号', width: 100, dataIndex: 'recBankNo' },
            { title: '银行名称', width: 100, dataIndex: 'recBankName' },
            { title: '状态', width: 100, dataIndex: 'status_name' },
            { title: '操作时间', width: 200, dataIndex: 'time' },
            {
                title: '操作', dataIndex: 'Controls',
            },
        ];

        // recAccNo  收款人账号
        // recAccName  收款户名
        // isSameBank  是否跨行（1同行  2跨行）
        // amount  打款金额
        // memo  附言
        // recBankNo  行号
        // recBankName   银行名称
        // status_name  状态





        //批量制单列表        
        let batchData = ref<any>([]);

        //获取批量制单列表
        const api_cw_get_zdFun = async () => {

            batchData.value = [];

            let data: any = await api_cw_get_zd(user.openid);

            data.list.forEach((item: any) => {
                batchData.value.push(item)
            });
        }


        // 批量打款弹出框
        let shenheOpen = ref(false);

        //打开弹框数据
        let piliangdata: any = ref({});

        //是否打开子批次弹框
        let suborderOpen = ref<Boolean>(false)
        //是否打开子子批次弹框
        let suborderOpens = ref<Boolean>(false)


        //是否打开子批次弹框
        const OpenSuborderOpen = (e: any = '') => {
            console.log(e);

            if (e.id) {
                suborderOpen.value = !suborderOpen.value;
                piliangdata.value = e;
            } else {
                suborderOpens.value = !suborderOpens.value
                api_cw_get_zd_detailFun(piliangdata.value.id)
            }

        }

        //批量制单子列表
        let suborderData: any = ref<any>([]);

        //批量审核
        let BatchAuditData: any = ref<any>([]);

        //获取批量制单子列表
        const api_cw_get_zd_detailFun = async (id: string) => {
            suborderData.value = [];
            BatchAuditData.value = [];
            const data: any = await api_cw_get_zd_detail({ openid: user.openid, id });
            data.list.forEach((item: any) => {
                item.key = item.id;
                suborderData.value.push(item)
            });
            console.log(suborderData.value);

        }


        const rowSelections: TableProps['rowSelection'] = {
            type: 'checkbox',
            checkStrictly: true,
            onSelectAll: (selected, selectedRows, changeRows) => {
                console.log(selected, selectedRows, changeRows);

            },
            onChange: (selectedRowKeys, selectedRows) => {
                BatchAuditData.value = selectedRows;



            },
            getCheckboxProps: (record) => ({
                disabled: record.status !== '1'
            })

        };

        let auditTypes = ref<string>('0')


        const shenheOpenFun = (e: string) => {

            console.log(BatchAuditData.value);

            if (auditTypes.value === '0') {
                BatchAuditData.value.forEach((item: any) => {
                    loopRefuseFun(item)
                })
            } else {
                BatchAuditData.value.forEach((item: any) => {
                    console.log(item);
                    loopRemitFun(item)
                })
            }
            shenheOpen.value = !shenheOpen.value;
            suborderOpen.value = false

        }

        //循环拒绝
        const loopRefuseFun = async (item: any) => {
            let up = {
                openid: user.openid,
                id: item.id
            }
            console.log(up);
            let data: any = await api_cw_jujue_dk(up);
            console.log(data);
        }

        //循环打款
        const loopRemitFun = async (item: any) => {
            let up = {
                payAccName: user.payAccName,
                payAccNo: user.payAccNo,
                openid: user.openid,
                recAccName: item.recAccName,
                recAccNo: item.recAccNo,
                amount: item.amount,
                isSameBank: item.isSameBank,
                recBankNo: item.recBankNo,
                recBankName: item.recBankName,
                memo: item.memo,
                id: item.id
            }
            let data: any = await api_cw_single_pay(up);
            console.log(data);
        }


















        const fileList: any = ref<UploadProps['fileList']>([]);



        // const handleChange = (info: any) => {
        //     const status = info.file.status;
        //     if (status !== 'uploading') {
        //         // console.log(info.file, info.fileList);
        //     }
        //     console.log(info, '-------------------');

        //     if (status === 'done') {
        //         // message.success(`${info.file.name} 上传成功`);
        //         if (info.file.response.error === 0) {
        //             if (info.file.response.message) message.success(info.file.response.message);
        //         } else {
        //             if (info.file.response.message) message.error(info.file.response.message);
        //         }
        //     } else if (status === 'error') {
        //         message.error(`${info.file.name} file upload failed.`);
        //     }
        // };

        // function handleDrop(e: DragEvent) {
        //     console.log(e);
        // }


















        const uploading = ref<boolean>(false);




        const beforeUpload: UploadProps['beforeUpload'] = file => {
            fileList.value = [...(fileList.value || []), file];
            return false;
        };

        const handleUpload = async (type: number) => {
            const formData = new FormData();
            let file = fileList.value[0].originFileObj;
            formData.append('file', file);
            formData.append('openid', user.openid);
            let data = null;
            if (type === 0) {
                uploading.value = true;
                data = await api_cw_pl_pay(formData);
            } else {
                jsonData.value = [];
                jsonDataTitle.value = [];
                data = await api_index_pl_zd(formData);
            }
            uploading.value = false;
            fileList.value = [];

        };


        let activeKey = ref('1');

        // 下载批量支付模板
        const DownloadTemplate = () => {
            window.location.href = 'http://newappadmin.zhongzihuacai.com/cer/pay.xls';
        }

        //下载批量制单模板
        const DownloadTemplateZD = () => {
            window.location.href = 'https://cw.jibeimall.com/zd.xlsx';
        }


        watch(activeKey, (newValue, oldValue) => {
            console.log(newValue);
            if (newValue === '2') {
                api_cw_get_zdFun();
            } else {
                api_cw_get_zd_listFun();
            }


        })

        watch(suborderOpen, (newValue, oldValue) => {
            // BatchAuditData.value = []
            state.selectedRowKeys = []

        })



        let current = ref<number>(1);
        let total: any = ref(1)
        let list = ref<any>([]);


        //制单列表查看
        const api_cw_get_zd_listFun = async () => {
            list.value = [];
            let up = {
                openid: user.openid,
                pageNo: current.value
            }
            let data: any = await api_cw_get_zd_list(up);
            if (data.error === 0) {
                data.list.forEach((item: any) => {
                    let obj = {
                        recAccNo: item.recAccNo,
                        recAccName: item.recAccName,
                        isSameBank: item.isSameBank === '1' ? '否' : '是',
                        amount: item.amount,
                        memo: item.memo,
                        recBankNo: item.recBankNo ? item.recBankNo : '-',
                        recBankName: item.recBankName ? item.recBankName : '-',
                        status_name: item.status_name,
                        status: item.status,
                        time: item.time,
                        item
                    }
                    list.value.push(obj);
                })
            }
            console.log(data);
            total.value = data.total * 1;
        }










        const paginationChange = (e: number) => {
            current.value = e;
            api_cw_get_zd_listFun();
        }



        const MakePaymentFun = (e: any) => {

            if (e.status === '0') {
                // shenheOpen.value = !shenheOpen.value;
            } else {
                dakuanOpen.value = !dakuanOpen.value;
            }
            TemporaryStorage = e.item;
            // console.log(TemporaryStorage);
        };
        let TemporaryStorage = reactive<any>({});

        let zhuanzhangFun = async (resolve: any, reject: any) => {
            let up = {
                openid: user.openid,
                id: piliangdata.value.id
            }
            let data: any = await api_cw_piliang_single_pay(up);
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1);
        }



        //同意打款
        const api_cw_single_payFun = async () => {
            let up = {
                payAccName: user.payAccName,
                payAccNo: user.payAccNo,
                recAccName: TemporaryStorage.recAccName,
                recAccNo: TemporaryStorage.recAccNo,
                amount: TemporaryStorage.amount,
                isSameBank: TemporaryStorage.isSameBank,
                recBankNo: TemporaryStorage.recBankNo,
                recBankName: TemporaryStorage.recBankName,
                openid: user.openid,
                memo: TemporaryStorage.memo,
                id: TemporaryStorage.id
            }
            let data: any = await api_cw_single_pay(up);
            console.log(data);
            if (data.error === 0) {
                api_cw_get_zd_listFun()
            }
            dakuanOpen.value = !dakuanOpen.value;
            auditType.value = '0'
        };

        //取消或拒绝
        let api_cw_jujue_dkFun = async () => {
            let up = {
                openid: user.openid,
                id: TemporaryStorage.id
            }
            let data: any = await api_cw_jujue_dk(up);
            if (data.error === 0) {
                api_cw_get_zd_listFun();
            }
            dakuanOpen.value = !dakuanOpen.value;
            auditType.value = '0'

        }


        let dakuanOpen = ref(false);
        let auditType = ref<string>('0')





        const api_cw_cancle_dkFun = async (item: any) => {

            let up = {
                openid: user.openid,
                id: item.item.id
            }
            let data: any = await api_cw_cancle_dk(up);
            if (data.error === 0) {
                api_cw_get_zd_listFun();
            }
        }

        const api_cw_new_cancle_dkFun = async (item: any) => {
            let up = {
                openid: user.openid,
                id: item.id
            }
            const data: any = await api_cw_new_cancle_dk(up);
            console.log(data);
            if (data.error === 0) {
                api_cw_get_zdFun();
            }
        }



        return {
            jsonDataTitle,
            jsonData,
            suborderOpens,
            piliangdata,
            state,
            user,
            formState,
            rowSelections,
            batchColumns,
            TemporaryStorage,
            dakuanOpen,
            shenheOpen,
            auditType,
            auditTypes,
            uploading,
            fileList,
            list,
            current,
            total,
            columns,
            suborderColumns,
            activeKey,
            layout: 'vertical',
            rules: {
                amount: [{ type: 'number', required: true, message: '转账金额必须是数字', trigger: 'change' }]
            },
            batchData,
            suborderOpen, //是否打开子批次弹框
            suborderData,
            OpenSuborderOpen,
            showConfirm,
            handleRemove, // 删除已上传的批量制单
            zhuanzhangFun,// 打开转账弹框
            beforeUpload, // 上传批量制单
            handleUpload,// 制单
            // handleDrop, //废弃
            // handleChange, //废弃
            DownloadTemplate, //下载批量支付模板
            api_cw_document_preparationFun, // 单笔制单
            api_cw_cancle_dkFun, //取消制单
            DownloadTemplateZD, //下载批量制单模板
            api_cw_single_payFun, // 同意打款
            onFinishFailed, // 校验未通过请检查参数
            paginationChange, // 打款列表分页
            MakePaymentFun, // 打开审核弹框
            loopRemitFun, //循环打款
            shenheOpenFun,
            api_cw_new_cancle_dkFun,
            onSelectChange,
            uploadDraggerChange,
            api_cw_export_dataFun
        }


    }
}




</script>


<style lang="less" scoped>
.TransferOfMoney {
    width: 100%;
    height: 100%;
}

.piliangyulan_box {
    max-height: 300px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .piliangyulan_box_title {
        display: flex;
        border-bottom: 1px solid #f5f5f5;
        background-color: #fafafa;

        .piliangyulan_box_title_item {
            width: 200px;
            height: 50px;
            line-height: 50px;
        }
    }

    .piliangyulan_box_table {
        overflow: hidden;
        overflow-y: auto;
        flex: 1;

        .piliangyulan_box_table_row {
            display: flex;
            border-top: 1px solid #f5f5f5;

            .piliangyulan_box_table_row_item {
                width: 200px;
                height: 50px;
                line-height: 50px;
            }
        }

        .piliangyulan_box_table_row:hover {
            background: #f5f5f5;
            /* 应用于所有元素的过渡效果 */
            transition: all 0.2s ease-in-out;

        }
    }
}
</style>